<template>
  <v-container>
    <v-overlay :value="loading">
      <v-progress-circular
        absolute
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
    <div v-if="results">
      <GChart type="LineChart" :data="null" @ready="onChartReady" />
    </div>
    <v-select v-model="range" :items="ranges" label="Date range"></v-select>
  </v-container>
</template>

<script>
import api from "@/api";
import debounce from "debounce";
export default {
  data() {
    return {
      loading: null,
      results: [],
      chart: null,
      google: null,
      range: "FOUR_HOURS",
      ranges: [
        { text: "1 Hour", value: "ONE_HOUR" },
        { text: "2 Hours", value: "TWO_HOURS" },
        { text: "4 Hours", value: "FOUR_HOURS" },
        { text: "8 Hours", value: "EIGHT_HOURS" },
        { text: "12 Hours", value: "TWELVE_HOURS" },
        { text: "Today", value: "TODAY" },
        { text: "Yesterday", value: "YESTERDAY" },
        { text: "3 Days", value: "THREE_DAYS" },
        { text: "Week", value: "WEEK" }
      ]
    };
  },
  mounted() {
    window.addEventListener("resize", debounce(this.drawChart, 200));
  },
  watch: {
    results: {
      deep: true,
      handler() {
        this.drawChart();
      }
    },
    range: {
      deep: true,
      handler() {
        this.refresh();
      }
    }
  },
  async created() {
    this.refresh();
  },
  methods: {
    async refresh() {
      this.loading = true;
      const results = await api.getSpeedTestResults(this.range);
      for (var i in results) {
        results[i].started = new Date(Date.parse(results[i].started + "Z"));
      }
      this.results = results;
      this.loading = false;
    },
    onChartReady(chart, google) {
      this.google = google;
      this.chart = chart;

      this.drawChart();
    },
    drawChart() {
      if (!this.chart || !this.google) return null;

      const dataTable = new this.google.visualization.DataTable();
      dataTable.addColumn("datetime", "Started");
      dataTable.addColumn("number", "Down");
      dataTable.addColumn("number", "Up");
      dataTable.addColumn("number", "Ping");

      for (var i = 0; i < this.results.length; i++) {
        var row = this.results[i];
        dataTable.addRow([
          new Date(row.started),
          parseFloat(row.down),
          parseFloat(row.up),
          parseFloat(row.ping)
        ]);
      }

      const options = {
        vAxes: {
          0: { gridlines: { color: "transparent" }, format: "#MBps" },
          1: { gridlines: { color: "transparent" }, format: "#ms" }
        },
        series: {
          0: { targetAxisIndex: 0 },
          1: { targetAxisIndex: 0 },
          2: { targetAxisIndex: 1 }
        },
        curveType: "function",
        height: "408"
      };

      this.chart.draw(dataTable, options);
    }
  }
};
</script>
