<template v-slot:item.url="{ item }">
  <div>
    <v-btn color="primary" @click.stop="dialog = true" small>Image</v-btn>
    <v-dialog v-model="dialog">
      <v-card>
        <v-card-title class="headline">SpeedTest Results</v-card-title>
        <v-card-text>
          <v-img :src="item.url" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      dialog: false
    };
  }
};
</script>
