<template>
  <v-container>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <ConnectedDevices v-if="data" :data="data"></ConnectedDevices>
  </v-container>
</template>

<script>
import axios from "axios";
import ConnectedDevices from "../components/ConnectedDevices.vue";

export default {
  components: {
    ConnectedDevices
  },
  data() {
    return {
      loading: false,
      data: null,
      errors: []
    };
  },
  created() {
    this.loading = true;
    axios
      .get(`/api/v1/connected-devices`)
      .then(response => {
        this.loading = false;
        this.data = response.data;
      })
      .catch(e => {
        this.loading = false;
        this.errors.push(e);
      });
  }
};
</script>
