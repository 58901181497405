import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isDataReady: false,
    results: []
  },
  getters: {
    results(state) {
      return state.results;
    },
    latestResult(state) {
      return state.results[0];
    },
    isDataReady(state) {
      return state.isDataReady;
    }
  },
  mutations: {
    SET_RESULTS(state, results) {
      state.results = results;
      for (var i in results) {
        results[i].started = new Date(Date.parse(results[i].started + "Z"));
      }
    },
    SET_IS_DATA_READY(state, value) {
      state.isDataReady = value;
    }
  },
  actions: {
    loadResults({ commit }) {
      Vue.axios
        .get("/api/v1/speedtest-results")
        .then(r => r.data)
        .then(results => {
          commit("SET_RESULTS", results);
        });
    }
  }
});
