<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <ISPChart></ISPChart>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col align="center">
        <SpeedGuage direction="Down"></SpeedGuage>
      </v-col>
      <v-col align="center">
        <SpeedGuage direction="Up"></SpeedGuage>
      </v-col>
      <v-col align="center">
        <LatestResults></LatestResults>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <ResultTable></ResultTable>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ISPChart from "../components/SpeedTestISPChart.vue";
import LatestResults from "../components/SpeedTestLatestResults.vue";
import ResultTable from "../components/SpeedTestResultTable.vue";
import SpeedGuage from "../components/SpeedTestLatestSpeedGuage.vue";

export default {
  components: {
    ISPChart,
    LatestResults,
    ResultTable,
    SpeedGuage
  }
};
</script>
