<template>
  <v-container>
    <div v-if="data">
      <GChart
        :settings="{ packages: ['corechart', 'gauge'] }"
        type="Gauge"
        :data="view"
        :options="options"
        @ready="onChartReady"
      />
    </div>
  </v-container>
</template>

<script>
export default {
  props: {
    direction: null
  },
  data() {
    return {
      view: null,
      upOptions: {
        min: 0,
        max: 15,
        redFrom: 0,
        redTo: 5,
        yellowFrom: 5,
        yellowTo: 10,
        greenFrom: 10,
        greenTo: 15,
        minorTicks: 1
      },
      downOptions: {
        min: 0,
        max: 50,
        redFrom: 0,
        redTo: 20,
        yellowFrom: 20,
        yellowTo: 35,
        greenFrom: 35,
        greenTo: 50,
        minorTicks: 5
      }
    };
  },
  computed: {
    data() {
      return this.$store.getters.latestResult;
    },
    up() {
      return this.direction === "Up";
    },
    options() {
      return this.up ? this.upOptions : this.downOptions;
    }
  },
  methods: {
    onChartReady(chart, google) {
      this.createDataTable(google);
    },
    createDataTable(google) {
      var result = this.data;

      var downGaugeData = new google.visualization.DataTable();
      downGaugeData.addColumn("number", this.up ? "Up" : "Down");
      downGaugeData.addRows(1);
      downGaugeData.setCell(0, 0, this.up ? result.up : result.down);

      this.view = downGaugeData;
    }
  }
};
</script>
