<template>
  <v-container>
    <v-img v-if="url" :src="url" />
  </v-container>
</template>

<script>
export default {
  computed: {
    url() {
      var result = this.$store.getters.latestResult;
      if (typeof result === "undefined") {
        return;
      }
      return result.url;
    }
  }
};
</script>
