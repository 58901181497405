<template>
  <v-container>
    <v-data-table :headers="headers" :items="results" class="elevation-1">
      <template v-slot:[`item.started`]="{ item }">
        <span>{{ new Date(item.started).toLocaleString() }}</span>
      </template>
      <template v-slot:[`item.url`]="{ item }">
        <Url :item="item"></Url>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import Url from "./SpeedTestResultTableUrl";

export default {
  components: {
    Url
  },
  data() {
    return {
      headers: [
        { text: "Destination", value: "destinationName" },
        { text: "Time", value: "started" },
        { text: "Ping", value: "ping" },
        { text: "Down", value: "down" },
        { text: "Up", value: "up" },
        { text: "Url", value: "url" }
      ]
    };
  },
  mounted() {
    this.$store.dispatch("loadResults");
  },
  computed: mapState(["results"])
};
</script>
