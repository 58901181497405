<template>
  <v-container>
    <v-alert v-if="errors.length" :type="error" dismissible>
      {{ errors }}
    </v-alert>
    <v-data-table
      :headers="headers"
      :items="data"
      :sort-by="['name']"
      class="elevation-1"
      disable-pagination
      :hide-default-footer="true"
    >
      <template v-slot:[`item.state`]="{ item }">
        <Alive :item="item"></Alive>
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <v-icon medium color="grey" @click="alive(item)"
          >mdi-refresh-circle</v-icon
        >
        <v-icon medium color="red" @click="showDeleteDialog(item)" class="ml-1"
          >mdi-close-box</v-icon
        >
      </template>
    </v-data-table>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title>Delete</v-card-title>
        <v-card-text
          >Are you sure you want to delete `{{
            itemToDelete.name
          }}`?</v-card-text
        >
        <v-card-actions>
          <v-btn color="primary" text @click="dialogDelete = false"
            >Close</v-btn
          >
          <v-btn color="primary" text @click="deleteItem()">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from "axios";
import Alive from "./ConnectedDevicesAlive";

export default {
  components: {
    Alive,
  },
  data() {
    return {
      headers: [
        {
          text: "Name",
          align: "start",
          value: "name",
        },
        { text: "Mac Address", value: "mac" },
        { text: "IP Address", value: "ipv4" },
        { text: "State", value: "state" },
        { text: "Action", value: "action" },
      ],
      errors: [],
      itemToDelete: {
        name: null,
      },
      dialogDelete: false,
    };
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  methods: {
    alive(item) {
      item.alive = null;
      this.$forceUpdate();
      axios
        .get("/api/v1/connected-devices/" + item.id + "/alive")
        .then((response) => {
          item.status = response.data.status;
          this.$forceUpdate();
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    showDeleteDialog(item) {
      this.itemToDelete = item;
      this.dialogDelete = !this.dialogDelete;
    },
    deleteItem() {
      this.dialogDelete = false;
      axios
        .delete("/api/v1/connected-devices/" + this.itemToDelete.id)
        .then(() => {
          this.$forceUpdate();
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
  },
};
</script>
