import Vue from "vue";
import Vuex from "vuex";
import App from "./App.vue";
import router from "./router";
import axios from "axios";
import VueAxios from "vue-axios";
import vuetify from "./plugins/vuetify";
import store from "./store/store";
import VueGoogleCharts from "vue-google-charts";

Vue.use(Vuex);
Vue.use(VueGoogleCharts);
Vue.use(VueAxios, axios);

Vue.config.productionTip = false;

new Vue({
  store,
  router,
  vuetify,
  render: h => h(App)
}).$mount("#app");
