import axios from "axios";

const client = axios.create({
  json: true
});

export default {
  async execute(method, resource, data) {
    return client({
      method,
      url: resource,
      data
    }).then(req => {
      return req.data;
    });
  },
  getSpeedTestResults(range) {
    return this.execute(
      "get",
      `/api/v1/speedtest-results?range=${range}`
    );
  }
};
