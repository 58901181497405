<template v-slot:item.state="{ item }">
  <div>
    <v-progress-circular
      v-if="item.state === null || item.state === 'UNKNOWN'"
      :size="24"
      indeterminate
    ></v-progress-circular>
    <v-icon v-if="item.state === 'UP'" medium color="green"
      >mdi-check-circle</v-icon
    >
    <v-icon v-if="item.state === 'DOWN'" color="red" medium
      >mdi-alert-circle</v-icon
    >
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
};
</script>
